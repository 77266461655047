@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.barber-name{
  font-family: 'Roboto', sans-serif;
  font-size: x-large;
  font-weight: 700;
  margin-bottom: 10px;
}

.schedule-list{
  width: 100%;
}

.schedule-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.weekday {
  margin-bottom: 0;
  text-align: right;
  margin-left: 0px;
  margin-right: 6px;
}

.info-label {
  margin-bottom: 0px;
  justify-content: space-between;
  text-align: left;
  margin-left: 0px;
  margin-right: 6px;
  margin-top: 8px;
}

.custom-modal-wrapper {
  align-items: flex-end !important; /* Align modal at the bottom */
}

.custom-modal-title{
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0;
}

.custom-modal-dialog {
  max-width: 90%; /* Adjust the maximum width of the modal */
  margin: 10px auto; /* Add margin to center the modal horizontally */
}

.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Customize the overlay background */
}

.modal-content {
  padding: 20px; /* Add padding to the modal content */
  background-color: white; /* Customize the modal content background */
}

.info {
  text-align: left;
  font-weight: 700;
  margin-left: 50px;
  margin-right: 40px;
  margin-top: 0;
  margin-bottom: 0px;
}

.interval {
  margin-left: 8px;
  margin-bottom: 0;
}

/* Hicon
.checkbox{
  position: absolute;
  width: 24px;
  height: 24px;
  left: 32px;
  top: 244px;
} */

.checkbox-wrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  font-size: 10px;
  /* creating a custom design */
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid #c8c8c8;
  outline: none;
  cursor: pointer;
  vertical-align: center;
}

.checkbox-wrapper{
  justify-content: center;
  font-size: 14px;
  display: flex;
  align-items: center;
}

input.checked {
  position: relative;
}

input.checked::before {
  content: "✔";
  font-size: 24px;
  color: green;
  position: absolute;
  right: -3px;
  top: -3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.countdown-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px;
}

.countdown-text > p {
  font-size: 13px;
  font-weight: 400;
  color: #2D1D35;
}

.countdown-text > button {
  background-color: #fff;
  border: none;
  color: #FE5B00;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.submit-btn {
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  padding: 10px;
  background: linear-gradient(91deg, #9076FC 0%, #6950FF 100%);
}


input {
  border-radius: 10px;
  border: 1.5px solid #DEE1EA;
  padding: 12px 10px;
}

input:focus {
  border-color: #506CFF;
  font-display: auto;
} 

.HairCutImage{
  /* aspect-ratio: 0.75; */
  /* object-fit: auto; */
  border-radius: 40%;
  border: 2px solid black;
}
  
.footer {
  box-sizing: border-box;
  margin: 0;
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
  background-color: #f9f6ed;
  color: white;
  text-align: center;
  justify-items: space-between;
}

.footer-list {
  list-style-type: none;
  padding: 5px;
}

.footer-link {
  color: black;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}